import React from "react";
import { Breadcrumbs, Link, Typography, Container } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Breadcrumb = ({ items }) => {
  return (
    <Container sx={{ marginY: 2 }}>
      <nav aria-label="breadcrumb">
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="small" sx={{ color: "#bbb" }} />
          } // Light gray separator for subtle effect
          aria-label="breadcrumb"
          sx={{
            "& .MuiBreadcrumbs-separator": {
              marginLeft: "0.25rem",
              marginRight: "0.25rem",
            },
          }}
        >
          {items.map((item, index) =>
            item.href ? (
              <Link
                key={index}
                href={item.href}
                sx={{
                  fontSize: "0.875rem",
                  color: "#555", // Dark gray for contrast against white background
                  textTransform: "uppercase",
                  letterSpacing: "0.05em",
                  transition: "color 0.3s ease",
                  "&:hover": {
                    color: "#000", // Turn black on hover for stronger contrast
                  },
                }}
              >
                {item.label}
              </Link>
            ) : (
              <Typography
                key={index}
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  color: "#000", // Black for the current page to stand out
                  textTransform: "uppercase",
                  letterSpacing: "0.05em",
                }}
              >
                {item.label}
              </Typography>
            )
          )}
        </Breadcrumbs>
      </nav>
    </Container>
  );
};

export default Breadcrumb;
