import React, { Suspense } from "react";
import UsefulLinksComponent from "../../UsefulLinks/UsefulLinksComponent";

const UsefulLinksModal = ({ modalOpen, setModalOpen, isHomePage }) => (
  <>
    {modalOpen && isHomePage && (
      <Suspense fallback={<div>Loading...</div>}>
        <UsefulLinksComponent open={modalOpen} setModalOpen={setModalOpen} />
      </Suspense>
    )}
  </>
);

export default UsefulLinksModal;
