import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import BrandContainer from "../brand-container/BrandContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Menu03Icon } from "../Menu03Icon";

const Navbar = ({
  visibleLinks,
  dropdownLinks,
  isDropdownOpen,
  anchorEl,
  handleDropdownOpen,
  handleDropdownClose,
  handleLinkClick,
  isHomePage,
  theme,
  location,
  navbarHeight,
  isSmallScreen,
  toggleDrawer,
  drawerOpen,
}) => (
  <AppBar
    position="fixed"
    sx={{
      backgroundColor: "#FFFFFF",
      color: "#000",
      height: navbarHeight,
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      transition: "height 0.3s ease, box-shadow 0.3s ease",
      textTransform: "uppercase",
      display: "flex", // Added flex display to AppBar
      justifyContent: "center", // Centers Toolbar vertically within AppBar
    }}
  >
    {/* Center the content within the AppBar */}
    <Toolbar
      sx={{
        justifyContent: "space-between",
        px: 2,
        width: "100%",
        alignItems: "center", // Centers items vertically
        minHeight: "100%", // Ensures Toolbar uses full height of AppBar
      }}
    >
      {/* Brand / Logo */}
      <Link to="/" style={{ textDecoration: "none" }}>
        <BrandContainer />
      </Link>

      {/* Navigation Links */}
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          alignItems: "center",
          gap: { xs: "5px", sm: "10px", md: "18px" }, // Responsive gaps
        }}
      >
        {visibleLinks
          .filter((link) => !link.showOnHome || isHomePage)
          .map((link) => (
            <Link
              key={link.name}
              to={link.href}
              onClick={() => handleLinkClick(link)}
              style={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "6px",
                  padding: "5px",
                  color:
                    location.pathname === link.href
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                  "&:hover": {
                    // color: theme.palette.secondary.main,
                    backgroundColor: "rgba(0, 0, 0, 0.09)",
                    transition: "background 0.3s ease, color 0.3s ease",
                  },
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "0.95rem" }, // Responsive font sizes
                }}
              >
                <link.icon sx={{ fontSize: { xs: 18, sm: 20 } }} />
                <span
                  style={{
                    fontWeight: 600,
                    letterSpacing: "0.8px",
                    fontSize: "inherit", // Inherit font size for consistency
                  }}
                >
                  {link.name}
                </span>
              </Box>
            </Link>
          ))}

        {/* Dropdown Links */}
        {dropdownLinks.length > 0 && (
          <>
            <Button
              onClick={handleDropdownOpen}
              sx={{
                color: theme.palette.primary.main,
                px: 1.5,
                py: 0.75,
                borderRadius: "6px",
                fontSize: { xs: "0.875rem", sm: "1rem", md: "0.95rem" },
                fontWeight: 600,
                "&:hover": {
                  // color: theme.palette.secondary.main,
                  backgroundColor: "rgba(0, 0, 0, 0.09)",
                  transition: "background 0.3s ease, color 0.3s ease",
                },
              }}
              endIcon={isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              More
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isDropdownOpen}
              onClose={handleDropdownClose}
              PaperProps={{
                sx: {
                  background: "#FFFFFF",
                  color: theme.palette.text.primary,
                  mt: 1,
                  borderRadius: "8px",
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
                  "& .MuiMenuItem-root": {
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  },
                },
              }}
            >
              {dropdownLinks
                .filter((link) => !link.showOnHome || isHomePage)
                .map((link) => (
                  <MenuItem
                    key={link.name}
                    component={Link}
                    to={link.href}
                    onClick={() => {
                      handleLinkClick(link);
                      handleDropdownClose();
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: theme.palette.text.primary,
                    }}
                  >
                    <link.icon sx={{ fontSize: 20 }} />
                    {link.name}
                  </MenuItem>
                ))}
            </Menu>
          </>
        )}
      </Box>

      {/* Mobile Menu Icon */}
      {isSmallScreen && !drawerOpen && (
        <IconButton
          edge="start"
          color="inherit"
          onClick={toggleDrawer(true)}
          sx={{ color: theme.palette.text.primary }}
        >
          <Menu03Icon />
        </IconButton>
      )}
    </Toolbar>
  </AppBar>
);

export default Navbar;
