import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Container,
} from "@mui/material";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Grid from "@mui/material/Grid2";
import { Email, Phone, LocationOn, ArrowUpward } from "@mui/icons-material";
import FooterMap from "./FooterMap"; // Keeping the map component as is

const Footer = () => {
  // State to manage visibility of Go-to-Top button
  const [showGoToTop, setShowGoToTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // Show button if scroll position is more than 100vh
      setShowGoToTop(window.scrollY > window.innerHeight);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Container>
      <Box
        component="footer"
        sx={{
          pt: 4,
          mt: 2,
          pb: 4,
        }}
        id="footer-section"
      >
        <Typography variant="h2" sx={{ textAlign: "center", mb: 4 }}>
          Connect with us
        </Typography>
        <Grid
          container
          spacing={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid size={{ xs: 12, md: 6 }}>
            <FooterMap />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            {/* Address and Contact Info */}
            <Box>
              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ListItem sx={{ justifyContent: "center" }}>
                  <LocationOn sx={{ mr: 1 }} />
                  <Typography>
                    Kageshwori Manohara Municipality-8, Kathmandu, Nepal
                  </Typography>
                </ListItem>
                <ListItem sx={{ justifyContent: "center" }}>
                  <Email sx={{ mr: 1 }} />
                  <Typography>info@geotechspace.com.np</Typography>
                </ListItem>
                <ListItem sx={{ justifyContent: "center" }}>
                  <Phone sx={{ mr: 1 }} />
                  <Typography>+977 01-5920291</Typography>
                </ListItem>
                <ListItem sx={{ justifyContent: "center" }}>
                  <PhoneAndroidIcon />
                  <Typography>+977 9846789573</Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
        {/* Footer Bottom */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={4}
          py={2}
          sx={{ borderTop: "1px solid #e0e0e0", textAlign: "center" }}
        >
          <Typography variant="body2" color="textSecondary">
            &copy; 2024 Geotech Engineering Space Pvt. Ltd. All rights reserved.
          </Typography>
        </Box>

        {/* Go to Top Button */}
        {showGoToTop && (
          <IconButton
            color="primary"
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            aria-label="Go to top"
          >
            <ArrowUpward />
          </IconButton>
        )}
      </Box>
    </Container>
  );
};

export default Footer;
