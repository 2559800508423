import React from "react";
import styles from "./LoadingAnimation.module.css"; // Import the CSS module

const LoadingAnimation = ({ message }) => {
  return (
    <div className={styles.loadingAnimation}>
      <div className={styles.spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {message && <div className={styles.message}>{message}</div>}{" "}
      {/* Display message if provided */}
    </div>
  );
};

export default LoadingAnimation;
