import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"; // Import Breadcrumb component
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";

// Lazy load the AboutUs component
const AboutUs = lazy(() => import("../components/aboutUs/AboutUs"));

export default function AboutUsPage() {
  return (
    <>
      <Helmet>
        <title>About Us - Geotech Engineering Space</title>
        <meta
          name="description"
          content="Learn more about Geotech Engineering Space and our mission."
        />
      </Helmet>
      {/* Breadcrumb Navigation */}
      <Breadcrumb
        items={[{ href: "/", label: "Home" }, { label: "About Us" }]}
      />
      {/* Suspense to load the AboutUs component */}
      <Suspense fallback={<LoadingAnimation />}>
        <AboutUs />
      </Suspense>
    </>
  );
}
