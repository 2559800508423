import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Box } from "@mui/material";

const Layout = () => {
  return (
    <Box
      sx={{
        position: "relative", // Make parent relative
        minHeight: "100vh", // Full viewport height
      }}
    >
      <Header />

      {/* Main Content Area */}
      <Box
        sx={{
          position: "absolute", // Position this content absolutely
          top: "64px", // Set the top position below the header (adjust based on header height)
          left: 0,
          right: 0,
        }}
      >
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
