import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet-async";
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

// Lazy load the CRSTransformation component
const EngineeringToolsComponent = lazy(() =>
  import("../components/EngineeringTools/EngineeringTools")
);

const EngineeringTools = () => {
  return (
    <>
      <Helmet>
        <title>Engineering Tools - Geotech Engineering Space</title>
        <meta
          name="description"
          content="Access various engineering tools and services."
        />
      </Helmet>
      {/* Breadcrumb Navigation */}
      <Breadcrumb
        items={[{ href: "/", label: "Home" }, { label: "Engineering Tools" }]}
      />

      <Suspense fallback={<LoadingAnimation />}>
        <EngineeringToolsComponent />
      </Suspense>
    </>
  );
};

export default EngineeringTools;
