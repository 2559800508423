// src/utils/api_urls.js
export const home_data_api = process.env.REACT_APP_HOME_DATA_API;
export const about_data_api = process.env.REACT_APP_ABOUT_DATA_API;
export const portfolio_data_api = process.env.REACT_APP_PORTFOLIO_DATA_API;
export const useful_data_api = process.env.REACT_APP_USEFUL_DATA_API;
export const useful_links_api = process.env.REACT_APP_USEFUL_LINKS_API;
export const coordinate_systems_api =
  process.env.REACT_APP_COORDINATE_SYSTEMS_API;
export const transform_coords_file_api =
  process.env.REACT_APP_TRANSFORM_COORDS_FILE_API;
export const transform_coords_single_api =
  process.env.REACT_APP_TRANSFORM_COORDS_SINGLE_API;
export const useful_data_download_api =
  process.env.REACT_APP_USEFUL_DATA_DOWNLOAD_API;
