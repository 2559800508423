import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  Link,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useful_links_api } from "../../utils/api_urls";
import CloseIcon from "@mui/icons-material/Close";

const UsefulLinksComponent = ({ open, setModalOpen }) => {
  const [links, setLinks] = useState({});
  const dialogRef = useRef(null);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await fetch(useful_links_api);
        const data = await response.json();

        // Categorize links
        const categorizedLinks = data.reduce((acc, link) => {
          const { category } = link;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(link);
          return acc;
        }, {});

        setLinks(categorizedLinks);
      } catch (error) {
        console.error("Error fetching useful links:", error);
      }
    };

    fetchLinks();
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      aria-labelledby="useful-links-dialog"
      fullWidth
      PaperProps={{
        sx: {
          marginTop: { xs: "70px", md: "5px" },
          borderRadius: 2,
          boxShadow: 24,
        },
      }}
      // Setting inert attribute for accessibility
      aria-hidden={!open} // Add this line to control aria-hidden
      ref={dialogRef} // Reference to manage focus
    >
      <DialogTitle color="primary">
        Useful Links
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Container>
          {Object.keys(links).map((category) => (
            <Box key={category} mb={4}>
              <Typography variant="h5" color="primary" gutterBottom>
                {category.replace("_", " ").toUpperCase()}
              </Typography>
              <List>
                {links[category].map((link) => (
                  <ListItem key={link.url}>
                    <Link
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                      variant="body1"
                    >
                      {link.name}
                    </Link>
                    {link.remark && (
                      <Typography variant="body2">{link.remark}</Typography>
                    )}
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default UsefulLinksComponent;
