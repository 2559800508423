import MapHandler from "../MapHandler/MapHandler";

export default function FooterMap() {
  const details = {
    latitude: 27.7036519,
    longitude: 85.3757167,
    title: "Geotech Engineering Space",
  };
  return (
    <MapHandler
      coords={[27.7036519, 85.3757167]}
      height={"35vh"}
      scrollWheelZoom={false}
      showLayerControl={false}
      showMarker={details}
      zoomLvl={17.8}
    />
  );
}
