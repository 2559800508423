import {
  FaHome,
  FaCogs,
  FaTasks,
  FaUser,
  FaExternalLinkAlt,
  FaYoutube,
  FaTools,
  FaEnvelope,
  FaDownload,
} from "react-icons/fa";

const navLinks = [
  { name: "Home", href: "/", icon: FaHome, tooltip: "Home" },
  {
    name: "Services",
    href: "#expertise",
    icon: FaCogs,
    tooltip: "Services",
    showOnHome: true, // Only show on homepage
  },
  {
    name: "Portfolio",
    href: "/portfolio",
    icon: FaTasks,
    tooltip: "Portfolio",
  },
  { name: "About Us", href: "/about-us", icon: FaUser, tooltip: "About Us" },

  {
    name: "Engineering Tools",
    href: "/engineering-tools",
    icon: FaTools,
    tooltip: "Engineering Tools",
  },
  {
    name: "Useful Data",
    href: "/useful-data",
    icon: FaDownload,
    tooltip: "Useful Data",
  },
  {
    name: "Useful Links",
    href: "#useful-links",
    icon: FaExternalLinkAlt,
    tooltip: "Useful Links",
    showOnHome: true,
    isDropdown: true,
  },
  {
    name: "Media",
    href: "#youtube-container-fluid",
    icon: FaYoutube,
    tooltip: "Media & Blogs",
    showOnHome: true, // Only show on homepage
  },
  {
    name: "Contact Us",
    href: "#footer-section",
    icon: FaEnvelope,
    tooltip: "Contact Us",
    showOnHome: true, // Only show on homepage
  },
];

export default navLinks;
