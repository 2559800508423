import {
  faWater,
  faRoad,
  faMap,
  faCrosshairs,
  faTint,
  faCloudRain,
  faLayerGroup,
  faPlug,
  faVideo,
  faGlobe,
  faSatellite,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

export const markerIcons = {
  'Hydropower Survey': { icon: faWater, color: 'red' },
  'Road Survey': { icon: faRoad, color: 'brown' },
  'Cadastral Survey': { icon: faMap, color: '#0E4565' },
  'Control Point Survey': { icon: faCrosshairs, color: 'blue' },
  'Water Supply Survey': { icon: faTint, color: 'purple' },
  'Storm Drainage Sewerage Survey': { icon: faCloudRain, color: 'orchid' },
  'Cross Section Survey': { icon: faLayerGroup, color: 'magenta' },
  'Transmission Line Survey': { icon: faPlug, color: '#B324B5' },
  'Drone Videography': { icon: faVideo, color: '#981212' },
  'GIS Mapping': { icon: faGlobe, color: 'brown' },
  'Remote Sensing': { icon: faSatellite, color: 'teal' },
  default: { icon: faMapMarkerAlt, color: 'red' }, // Default icon and color
};
