/** @jsxImportSource @emotion/react */ // Enable Emotion's jsx pragma
import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const BrandContainer = () => {
  const location = useLocation();
  const isHomepage = location.pathname === "/";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "#fff",
        zIndex: 9999,
        height: "40px",
      }}
    >
      {/* Brand Image */}
      <Box
        component="img"
        src="/static/images/logo/Geotech-Logo-cropped.webp"
        alt="Geotech Engineering Logo"
        sx={{
          width: { xs: "50px", sm: "70px" }, // Responsive image size
          transition: "transform 0.3s ease-in-out", // Smooth transition
          borderRadius: "5%", // Optional: Add border radius
          height: "40px",
        }}
      />

      {/* Text Section */}
      <Box
        sx={{
          padding: "5px 10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: { xs: "9px", sm: "11px" }, // Responsive font size
            textTransform: "uppercase",
            letterSpacing: "0.14em",
            fontWeight: 900,
            lineHeight: 1.2,
            textShadow: "3px 2px 4px rgba(0, 0, 0, 0.6)",
            opacity: 0.99,
          }}
        >
          Geotech Engineering Space
        </Typography>

        {isHomepage && (
          <Typography
            color="secondary"
            sx={{
              fontSize: { xs: "7px", sm: "9px" }, // Responsive font size
              letterSpacing: "0.14em",
              fontWeight: 500,
              lineHeight: 2,
              textShadow: "3px 2px 4px rgba(0, 0, 0, 0.6)",
            }}
          >
            Quality Matters
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default BrandContainer;
