import React from "react";
import {
  Drawer,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const DrawerMenu = ({
  drawerOpen,
  toggleDrawer,
  links,
  handleLinkClick,
  isHomePage,
  theme,
  location,
}) => (
  <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
    <Box>
      <IconButton
        edge="start"
        color="inherit"
        onClick={toggleDrawer(false)}
        sx={{ position: "absolute", top: "10px", right: "10px" }}
      >
        <CloseIcon />
      </IconButton>
      <List>
        {links
          .filter((link) => !link.showOnHome || isHomePage)
          .map((link) => (
            <ListItem
              key={link.name}
              component={Link}
              to={link.href}
              onClick={() => {
                handleLinkClick(link);
                toggleDrawer(false)();
              }}
              sx={{
                color:
                  location.pathname === link.href
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
                "&:hover": { color: theme.palette.secondary.main },
                padding: "5px 15px",
              }}
            >
              <ListItemIcon
                sx={{ color: "inherit", minWidth: 0, margin: "4px" }}
              >
                <link.icon />
              </ListItemIcon>
              <ListItemText primary={link.name} />
            </ListItem>
          ))}
      </List>
    </Box>
  </Drawer>
);

export default DrawerMenu;
