// theme.js
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// Define custom breakpoints, including 'mdLg' as a value between 'md' and 'lg'
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    mdLg: 1100, // Custom breakpoint
    lg: 1280,
    xl: 1536,
  },
};

// Create light mode theme with custom breakpoints
const lightTheme = createTheme({
  breakpoints, // Integrate the custom breakpoints into the theme
  palette: {
    mode: "light",
    primary: {
      main: "#0b3d91",
    },
    secondary: {
      main: "#fc3d21",
    },
    text: {
      primary: "#333",
    },
  },
  typography: {
    fontFamily: "Times New Roman, sans-serif",
    // Customize body1
    body1: {
      letterSpacing: "0.1rem", // Specific letter spacing for body1
      wordSpacing: "0.04rem", // Specific word spacing for body1
    },

    // Customize body2
    body2: {
      letterSpacing: "0.025rem", // Specific letter spacing for body2
      wordSpacing: "0.04rem", // Specific word spacing for body2
    },
    h1: {
      color: "#0b3d91",
      textTransform: "uppercase",
      fontSize: "3rem",
      fontWeight: 700,
      "@media (max-width:1280px)": {
        // lg screen size
        fontSize: "2.5rem",
      },
      "@media (max-width:1100px)": {
        // custom mdLg breakpoint
        fontSize: "2.25rem",
      },
      "@media (max-width:900px)": {
        // md screen size
        fontSize: "2rem",
      },
      "@media (max-width:600px)": {
        // sm screen size
        fontSize: "1.75rem",
      },
    },
    h2: {
      color: "#0b3d91",
      textTransform: "uppercase",
      fontWeight: 900,
      letterSpacing: "0.25rem",
      wordSpacing: "0.18rem",
      fontSize: "2.5rem",
      "@media (max-width:1280px)": {
        fontSize: "2.25rem",
      },
      "@media (max-width:1100px)": {
        fontSize: "2rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.75rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    },
    h3: {
      color: "#fff",
      textTransform: "uppercase",
      fontSize: "2rem",
      "@media (max-width:1100px)": {
        fontSize: "1.75rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.25rem",
      },
    },
    h4: {
      color: "#fff",
      textTransform: "uppercase",
      "@media (max-width:1100px)": {
        fontSize: "1.6rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.25rem",
      },
    },
    h5: {
      color: "#fff",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: "1.5rem",
      "@media (max-width:1100px)": {
        fontSize: "1.35rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.25rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1rem",
      },
    },
    h6: {
      color: "#fff",
      textTransform: "uppercase",
      fontSize: "1.25rem",
      "@media (max-width:1100px)": {
        fontSize: "1.15rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1rem",
      },
      "@media (max-width:600px)": {
        fontSize: "0.875rem",
      },
    },
  },
});

const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#fc3d21",
    },
    background: {
      default: "#121212",
      paper: "#1d1d1d",
    },
    text: {
      primary: "#ffffff",
    },
  },
});

// Make typography responsive
export const themes = {
  light: responsiveFontSizes(lightTheme),
  dark: responsiveFontSizes(darkTheme),
};
