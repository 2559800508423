import React, { useEffect, useState, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useful_data_api } from "../utils/api_urls";

// Lazy load the UsefulData component
const UsefulData = React.lazy(() =>
  import("../components/UsefulData/UsefulData")
);

const UsefulDataSectionPage = () => {
  const [usefulData, setUsefulData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(useful_data_api);
        setUsefulData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingAnimation />; // Show loading animation while data is being fetched
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Useful Data - Geotech Engineering Space</title>
        <meta
          name="description"
          content="Find useful data and resources for engineering projects."
        />
      </Helmet>
      <Breadcrumb
        items={[{ href: "/", label: "Home" }, { label: "Useful Data" }]}
      />
      {/* Wrap the lazily-loaded component with Suspense */}
      <Suspense fallback={<LoadingAnimation />}>
        <UsefulData nepalData={usefulData} />
      </Suspense>
    </>
  );
};

export default UsefulDataSectionPage;
