// index.js
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeToggleProvider } from "./ThemeContext";

const container = document.getElementById("parent");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeToggleProvider>
      <CssBaseline />
      <App />
    </ThemeToggleProvider>
  </React.StrictMode>
);

reportWebVitals();
