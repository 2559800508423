import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { markerIcons } from "../portfolio/Map/markerIcons";
import { Box } from "@mui/material"; // Importing Box from MUI

const ProjectMarkers = lazy(() => import("../portfolio/Map/ProjectMarkers"));
const LegendControl = lazy(() => import("../portfolio/Map/LegendControl"));
const GeoJsonLayer = lazy(() => import("../portfolio/Map/GeojsonLayer"));
const FlyToMarker = lazy(() => import("../EngineeringTools/FlyToMarker"));
const ProjectMarker = lazy(() => import("../LeafMarker/ProjectMarker"));
const Placemarks = lazy(() => import("../LeafMarker/Placemarks"));

const { BaseLayer } = LayersControl;

const defaultOptions = {
  zoom: 6.4,
  minZoom: 1,
  maxZoom: 20,
  zoomSnap: 0.1,
  zoomDelta: 0.5,
};

const MapHandler = ({
  coords,
  zoomLvl,
  showLayerControl = true,
  zoomControl = true,
  scrollWheelZoom = true,
  dragging = true,
  touchZoom = true,
  doubleClickZoom = true,
  projectsData = false,
  geojsonData = null,
  height,
  width = "100%",
  showMarker = false,
  updateMap = false,
  placemarksData = null,
}) => {
  const getZoomLevelAndHeight = (width) => {
    if (width < 350) return { zoom: 5, height: "70vh" };
    if (width < 768) return { zoom: 6, height: "50vh" };
    if (width < 992) return { zoom: 6.7, height: "45vh" };
    return { zoom: 6.5, height: "45vh" };
  };

  const [zoomLevel, setZoomLevel] = useState(
    getZoomLevelAndHeight(window.innerWidth).zoom
  );
  const [mapHeight, setMapHeight] = useState(
    getZoomLevelAndHeight(window.innerWidth).height
  );

  useEffect(() => {
    const handleResize = () => {
      const { zoom, height } = getZoomLevelAndHeight(window.innerWidth);
      setZoomLevel(zoom);
      setMapHeight(height);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box sx={{ width: width, height: height || mapHeight }}>
      <MapContainer
        center={coords}
        zoom={zoomLvl || zoomLevel}
        style={{ height: "100%", width: "100%" }} // Ensure map fills its container
        zoomControl={zoomControl}
        scrollWheelZoom={scrollWheelZoom}
        dragging={dragging}
        touchZoom={touchZoom}
        doubleClickZoom={doubleClickZoom}
        minZoom={defaultOptions.minZoom}
        maxZoom={defaultOptions.maxZoom}
        zoomSnap={defaultOptions.zoomSnap}
        zoomDelta={defaultOptions.zoomDelta}
      >
        {showLayerControl ? (
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; OpenStreetMap contributors"
              />
            </BaseLayer>
            <BaseLayer name="Google Satellite">
              <TileLayer
                url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                attribution="Map data &copy; Google"
              />
            </BaseLayer>
            <BaseLayer name="Google Streets">
              <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                attribution="Map data &copy; Google"
              />
            </BaseLayer>
            <BaseLayer name="Google Terrain">
              <TileLayer
                url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                attribution="Map data &copy; Google"
              />
            </BaseLayer>
          </LayersControl>
        ) : (
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
        )}

        {/* Conditionally render with Suspense */}
        {geojsonData && (
          <Suspense fallback={<div>Loading GeoJson...</div>}>
            <GeoJsonLayer geojsonData={geojsonData} />
          </Suspense>
        )}

        {projectsData && (
          <Suspense fallback={<div>Loading Projects...</div>}>
            <ProjectMarkers projects={projectsData} />
            <LegendControl projectTypes={projectsData} />
          </Suspense>
        )}

        {placemarksData && (
          <Suspense fallback={<div>Loading Placemarks...</div>}>
            <Placemarks projects={placemarksData} />
          </Suspense>
        )}

        {updateMap && (
          <Suspense fallback={<div>Updating map...</div>}>
            <FlyToMarker updateMap={updateMap} />
          </Suspense>
        )}

        {showMarker && (
          <Suspense fallback={<div>Showing marker...</div>}>
            <ProjectMarker
              project={showMarker}
              icon={markerIcons.default.icon}
              color={markerIcons.default.color}
            />
          </Suspense>
        )}
      </MapContainer>
    </Box>
  );
};

export default MapHandler;
