import MainNav from "./nav-menu/MainNav";
import navLinks from "./nav-links";

const Header = () => {
  return (
    <>
      <MainNav links={navLinks} />
    </>
  );
};

export default Header;
