import React, { useEffect, useState, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { portfolio_data_api } from "../utils/api_urls";

// Lazy load the PortfolioContainer component
const PortfolioContainer = React.lazy(() =>
  import("../components/portfolio/portfolio")
);

async function fetchProjectData() {
  const res = await fetch(portfolio_data_api, {
    method: "GET",
  });
  if (!res.ok) {
    throw new Error("Failed to fetch projects");
  }
  return res.json();
}

function categorizeProjectsByType(projects) {
  const categorizedProjects = {
    all_projects: projects, // Add all projects as a default "all" category
  };

  projects.forEach((project) => {
    const projectType = project.projectType || "Uncategorized"; // Default to "Uncategorized"
    if (!categorizedProjects[projectType]) {
      categorizedProjects[projectType] = [];
    }
    categorizedProjects[projectType].push(project);
  });

  return categorizedProjects;
}

const PortfolioPage = () => {
  const [groupedProjects, setGroupedProjects] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const projects = await fetchProjectData();
        const categorizedProjects = categorizeProjectsByType(projects);
        setGroupedProjects(categorizedProjects);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when data is fetched
      }
    };

    loadProjects();
  }, []);

  if (loading) {
    return <LoadingAnimation />; // Show loading animation while data is being fetched
  }

  if (!groupedProjects) {
    return <p>Error: Unable to load projects</p>; // Handle error state
  }

  return (
    <>
      <Helmet>
        <title>Portfolio - Geotech Engineering Space</title>
        <meta
          name="description"
          content="Explore our projects and engineering services."
        />
      </Helmet>
      <Breadcrumb
        items={[{ href: "/", label: "Home" }, { label: "Portfolio" }]}
      />
      {/* Wrap the lazily-loaded component with Suspense */}
      <Suspense fallback={<LoadingAnimation />}>
        <PortfolioContainer groupedProjects={groupedProjects} />
      </Suspense>
    </>
  );
};

export default PortfolioPage;
