import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Portfolio from "./pages/PortfolioPage";
import EngineeringTools from "./pages/EngineeringTools";
import NoPage from "./pages/NoPage";
import AboutUsPage from "./pages/AboutUsPage";
import UsefulDataSectionPage from "./pages/UsefulDataSectionPage";
import { HelmetProvider } from "react-helmet-async";

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="portfolio/" element={<Portfolio />} />
            <Route path="engineering-tools/" element={<EngineeringTools />} />
            <Route path="about-us/" element={<AboutUsPage />} />
            <Route path="useful-data/" element={<UsefulDataSectionPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}
