import React, { useState, useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DrawerMenu from "./DrawerMenu";
import UsefulLinksModal from "./UsefulLinksModal";

const MainNav = ({ links }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();

  // Media queries for different breakpoints
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMdLgScreen = useMediaQuery(theme.breakpoints.up("mdLg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const [navbarHeight, setNavbarHeight] = useState(65);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setNavbarHeight(window.scrollY > 75 ? 55 : 65);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  useEffect(() => {
    setModalOpen(isHomePage && window.location.hash === "#useful-links");
  }, [location, isHomePage]);

  const handleDropdownOpen = (event) => setAnchorEl(event.currentTarget);
  const handleDropdownClose = () => setAnchorEl(null);

  const toggleDrawer = (open) => () => setDrawerOpen(open);

  const smoothScrollTo = (hash) => {
    const targetElement = document.getElementById(hash.replace("#", ""));
    if (targetElement)
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleLinkClick = (link) => {
    if (link.href.startsWith("#")) {
      smoothScrollTo(link.href);
    } else {
      navigate(link.href);
    }
  };

  // Determine the number of visible links based on screen size
  let visibleLinksCount;
  if (isExtraLargeScreen) {
    visibleLinksCount = 6;
  } else if (isLargeScreen) {
    visibleLinksCount = 4;
  } else if (isMdLgScreen) {
    visibleLinksCount = 4; // Custom count for md-lg screens
  } else if (isMediumScreen) {
    visibleLinksCount = 4;
  } else {
    visibleLinksCount = 0; // 0 since we are using drawer menu for small screens
  }

  // Slice the links array based on the visible count
  const visibleLinks = links.slice(0, visibleLinksCount);
  const dropdownLinks = links.slice(visibleLinksCount);
  const isDropdownOpen = Boolean(anchorEl);

  return (
    <>
      <Navbar
        visibleLinks={visibleLinks}
        dropdownLinks={dropdownLinks}
        isDropdownOpen={isDropdownOpen}
        anchorEl={anchorEl}
        handleDropdownOpen={handleDropdownOpen}
        handleDropdownClose={handleDropdownClose}
        handleLinkClick={handleLinkClick}
        isHomePage={isHomePage}
        theme={theme}
        location={location}
        navbarHeight={navbarHeight}
        isSmallScreen={isSmallScreen}
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
      />
      <DrawerMenu
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        links={links}
        handleLinkClick={handleLinkClick}
        isHomePage={isHomePage}
        theme={theme}
        location={location}
      />
      <UsefulLinksModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isHomePage={isHomePage}
      />
    </>
  );
};

export default MainNav;
