import React, { useEffect, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";
import { home_data_api } from "../utils/api_urls";
import SocialSidebar from "../components/socialSidebar/SocialSidebar";

// Lazy load components
const Carousel = lazy(() => import("../components/carousel/CarouselSection"));
const MediaBlogs = lazy(() =>
  import("../components/VideoGallery/VideoGallery")
);
const ClientsSection = lazy(() =>
  import("../components/clientSection/ClientSection")
);
const ServicesSection = lazy(() => import("../components/services/Expertise"));

const Home = () => {
  const [carouselItems, setCarouselItems] = useState([]);
  const [mediaBlogs, setMediaBlogs] = useState([]);
  const [clients, setClients] = useState([]);
  const [services, setServices] = useState([]);

  // Separate loading states for each component
  const [loadingCarousel, setLoadingCarousel] = useState(true);
  const [loadingMediaBlogs, setLoadingMediaBlogs] = useState(true);
  const [loadingClients, setLoadingClients] = useState(true);
  const [loadingServices, setLoadingServices] = useState(true);

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(home_data_api, {});
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();

        setCarouselItems(result.carousel_items);
        setMediaBlogs(result.media_blogs);
        setClients(result.clients);
        setServices(result.services);
      } catch (err) {
        setError(err);
      } finally {
        setLoadingCarousel(false);
        setLoadingMediaBlogs(false);
        setLoadingClients(false);
        setLoadingServices(false);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Geotech Engineering Space - Quality Matters</title>
        <meta
          name="description"
          content="Geotech Engineering Space is an Engineering firm established
           to fulfill the gap created in the engineering market by delivering quality and reliable services"
        />
      </Helmet>
      <SocialSidebar />

      {loadingCarousel && <LoadingAnimation />}
      {!loadingCarousel && (
        <Suspense fallback={<LoadingAnimation />}>
          <Carousel items={carouselItems} />
        </Suspense>
      )}
      {loadingServices && <LoadingAnimation />}
      {!loadingServices && (
        <Suspense fallback={<LoadingAnimation />}>
          <ServicesSection services={services} />
        </Suspense>
      )}
      {loadingMediaBlogs && <LoadingAnimation />}
      {!loadingMediaBlogs && (
        <Suspense fallback={<LoadingAnimation />}>
          <MediaBlogs videos={mediaBlogs} />
        </Suspense>
      )}
      {loadingClients && <LoadingAnimation />}
      {!loadingClients && (
        <Suspense fallback={<LoadingAnimation />}>
          <ClientsSection clientList={clients} />
        </Suspense>
      )}
    </>
  );
};

export default Home;
