import React from "react";
import { Box, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const SocialSidebar = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "10%",
        right: 0,
        transform: "translateY(-50%)",
        display: { xs: "none", sm: "none", md: "flex" }, // Hide on extra-small screens, show on small and up
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.paper",
        boxShadow: 3,
        p: 1,
        borderRadius: "8px 0px 0px 8px",
        zIndex: 99999999999999,
      }}
    >
      <IconButton
        href="https://www.facebook.com/profile.php?id=100083312434061"
        target="_blank"
        color="primary"
      >
        <FontAwesomeIcon icon={faFacebook} size="sm" />
      </IconButton>
      <IconButton
        href="https://www.youtube.com/@Geotechspark"
        target="_blank"
        color="primary"
      >
        <FontAwesomeIcon icon={faYoutube} size="sm" />
      </IconButton>
      <IconButton
        href="https://www.instagram.com/geotech_021/"
        target="_blank"
        color="primary"
      >
        <FontAwesomeIcon icon={faInstagram} size="sm" />
      </IconButton>
    </Box>
  );
};

export default SocialSidebar;
